//Libraries
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

//Hooks
import { useImageQuery } from '../hooks/useImageQuery'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Styles
import styles from './css-pages/pages.module.scss'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'

const NotFoundPage = () => {
  const images = useImageQuery()
  return (
    <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Not Found</title>
          <meta
            name="description"
            content="This is 404 page, meaning this page does not exist. Please try another link to find the correct page you were looking for."
          />
      </Helmet>
      
      <Header />

      <Layout>
        <article className={styles.errorPageContainer}>
          <Img
            fluid={images.ErrorPageIcon.childImageSharp.fluid}
            alt="Error page telescope icon"
            className={styles.errorPageIcon}
          />
          <div>
            <h2>Not Found</h2>
            <p>Something went wrong...</p>
            <p>The link you clicked may not exist</p>
            <Link to="/">Click here to go back to the main page</Link>
          </div>
        </article>
      </Layout>
      <Footer />
    </FontSizeProvider>
  )
}

export default NotFoundPage
